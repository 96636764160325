import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Loader,
  Table,
  type TableColumn,
  TableConfigurationButton,
  TableProvider,
  type TableRowAddon,
  type TableSelectAddon,
  Tag,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  type BillableExpense,
  type BillableExpenseLine,
  type BillableExpensesResponse,
  type InvoiceLineMutate,
  useGetBillableExpensesQuery,
} from "@api/invoices";
import {
  BatchApplyObject,
  type BatchApplyObjectOnChangeHandler,
} from "@components/batch-apply-object";
import {
  type StrictValuesFilters,
  TableFilterControls,
} from "@components/table-filter";
import {
  defaultArrayFormatter,
  type QueryItem,
} from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { useApplyObject } from "@hooks/use-apply-object";
import { getTransactionRoute, getTransactionType } from "@src/bills/utils";
import { CURRENCY_FORMAT, INVOICE_STRINGS } from "@src/jobs";
import * as analytics from "@utils/analytics";
import { capitalize } from "@utils/capitalize";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { stringCompare } from "@utils/string-compare";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isEqual from "date-fns/isEqual";

import { useInvoice } from "./invoice-context";

const DEFAULT_SORT_BY = "doc_number";

type InvoiceLineCostDialogProps = {
  job: { id: string | number; name: string };
  onSave: (lines: InvoiceLineMutate[]) => Promise<void>;
  onCancel: () => void;
  selected: BillableExpensesResponse;
  onSelect: (transactions: BillableExpensesResponse) => void;
};

const isLine = (data: unknown): data is BillableExpenseLine =>
  typeof data === "object" && data !== null && !("data" in data);

const isBetween = (date: Date, from: Date, to: Date) =>
  (isEqual(from, date) || isBefore(from, date)) &&
  (isEqual(to, date) || isAfter(to, date));

type ExtraData = (
  | {
      groupLabel: string;
      value: "bill";
      label: "Bill";
    }
  | {
      groupLabel: string;
      value: "receipt";
      label: "Receipt";
    }
  | {
      groupLabel: string;
      value: "not_invoiced";
      label: "Billable";
    }
)[];

const getFilterGroup = (
  filters: QueryItem[],
  expect:
    | "account"
    | "vendor"
    | "cost_code"
    | "payment_account"
    | "transaction_status"
) => {
  const values = [];

  for (const filter of filters) {
    if (filter.dataIndex !== expect) continue;

    values.push(filter.value);
  }

  return values;
};

const getRangeFilter = (filters: QueryItem[]) => {
  let from: null | Date = null;
  let to: null | Date = null;

  for (const filter of filters) {
    if (filter.dataIndex === "date_after") {
      from = parseDate(String(filter.value), "yyyy-MM-dd") as null | Date;
    }
    if (filter.dataIndex === "date_before") {
      to = parseDate(String(filter.value), "yyyy-MM-dd") as null | Date;
    }
  }

  return { from, to };
};

const BILLABLE_STATUS_FILTER = (
  [
    { value: "not_invoiced", label: "Billable" },
    /**
     * @todo Uncomment these lines when we have the
     * ability to filters by theses statuses.
     */
    // { value: "invoiced", label: "Drawn" },
    // { value: "not_eligible", label: "Not eligible" },
  ] as const
).map((filter) => ({ ...filter, groupLabel: "Billable status" }));

/**
 * @todo Remove this lint error as soon as we start using this filter.
 */
const QUICKBOOKS_STATUS_FILTER = // eslint-disable-line
  (
    [
      { value: "billed", label: "Billed" },
      { value: "billable", label: "Billable" },
      { value: "not_selected", label: "Not selected" },
    ] as const
  ).map((filter) => ({ ...filter, groupLabel: "QuickBooks billable status" }));

const TRANSACTION_TYPE_FILTER = (
  [
    { value: "bill", label: "Bill" },
    { value: "receipt", label: "Receipt" },
  ] as const
).map((filter) => ({ ...filter, groupLabel: "Transaction type" }));

const TRANSACTION_STATUS = "Transaction status";

const BILL_STATUS_FILTER = [
  { value: "FOR_PAYMENT", label: "For payment" },
  { value: "ACH_INFO_REQUESTED", label: "ACH info requested" },
  { value: "ACH_PROCESSING", label: "Payment processing" },
  { value: "PAID", label: "Paid", groupLabel: "Status" },
];

const EXPENSE_STATUS_FILTER = [
  {
    label: "Reviewed",
    value: "REVIEWED",
    groupLabel: TRANSACTION_STATUS,
  },
];
const TRANSACTION_STATUS_FILTER = [
  ...EXPENSE_STATUS_FILTER.map((filter) => ({
    ...filter,
    label: `Receipt: ${filter.label}`,
    value: `receipt_${filter.value}`,
    groupLabel: TRANSACTION_STATUS,
  })),
  ...BILL_STATUS_FILTER.map((filter) => ({
    ...filter,
    label: `Bill: ${filter.label}`,
    value: `bill_${filter.value}`,
    groupLabel: TRANSACTION_STATUS,
  })),
];

const TABLE_HEIGHT = 427;

const EMPTY_BILLABLE_EXPENSES: BillableExpensesResponse = [];

const transformReviewStatusValueToLabel = (value: string) =>
  capitalize(value.split("_").join(" "));

export const InvoiceLineCostDialog = memo(
  ({
    job,
    onSave,
    onCancel,
    onSelect,
    selected,
  }: InvoiceLineCostDialogProps) => {
    const { id: invoiceId } = useInvoice(["id"]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
      data = EMPTY_BILLABLE_EXPENSES,
      refetch,
      isLoading,
      isFetching,
    } = useGetBillableExpensesQuery(
      { customerId: job.id },
      { refetchOnMountOrArgChange: true }
    );

    const { data: paymentAccounts } = useAccountsSimplified({
      filters: {
        only_payment_accounts: true,
        can_accounts_link_to_lines_desktop: true,
      },
    });

    const applyObject = useApplyObject({
      items: selected,
      resource: "lines",
    });

    const extraData = useMemo(() => {
      return [
        ...TRANSACTION_TYPE_FILTER,
        ...TRANSACTION_STATUS_FILTER,
        ...paymentAccounts.map((account) => ({
          ...account,
          groupLabel: "Payment account",
        })),
        ...BILLABLE_STATUS_FILTER,
      ];
    }, [paymentAccounts]);

    const getExtraFilter = useCallback(
      (value: (typeof extraData)[number]["value"]) =>
        extraData.find(
          (filter) => filter.value === value
        ) as (typeof extraData)[number],
      [extraData]
    );

    const hasExtraFilter = useCallback(
      (filters: QueryItem[], expect: ExtraData[number]["value"]) =>
        filters.some((filter) => filter.value === getExtraFilter(expect).value),
      [getExtraFilter]
    );

    const initialFilters = useMemo(
      () =>
        ({
          [getExtraFilter("not_invoiced").value]:
            getExtraFilter("not_invoiced"),
        }) as StrictValuesFilters,
      [getExtraFilter]
    );

    const {
      setFilters,
      filters = [],
      rawFilters,
    } = useTableFilters({
      formatter: defaultArrayFormatter,
      initialFilters,
    });

    const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY);

    const hasTags = Object.values(rawFilters).some(
      (filter) => !!filter && typeof filter !== "string"
    );

    const hasFilters = Object.values(rawFilters).some((filter) => !!filter);

    const searchResults = useMemo(() => {
      const items: BillableExpensesResponse = [];

      if (!hasFilters) return data;

      for (const item of data) {
        const belongsToBillableStatusBillable =
          (hasExtraFilter(filters, "not_invoiced") &&
            item.billableStatus === "Billable" &&
            !item.draw) ||
          !hasExtraFilter(filters, "not_invoiced");

        if (!belongsToBillableStatusBillable) continue;

        const belongsToTransactionType =
          (!hasExtraFilter(filters, "bill") &&
            !hasExtraFilter(filters, "receipt")) ||
          (hasExtraFilter(filters, "bill") &&
            getTransactionType(item.parent.url) === "Bill") ||
          (hasExtraFilter(filters, "receipt") &&
            getTransactionType(item.parent.url) === "Receipt");

        if (!belongsToTransactionType) continue;

        const transactionStatusFilters = getFilterGroup(
          filters,
          "transaction_status"
        );

        const belongsToTransactionStatus =
          transactionStatusFilters.length === 0 ||
          transactionStatusFilters.some((status) => {
            if (typeof status !== "string") return false;
            if (
              status.includes("receipt") &&
              item.parent.url.includes("expense") &&
              status.includes(item.parent.reviewStatus)
            )
              return true;
            if (
              status.includes("bill") &&
              item.parent.url.includes("bill") &&
              status.includes(item.parent.reviewStatus)
            )
              return true;
            return false;
          });

        if (!belongsToTransactionStatus) continue;

        const vendors = getFilterGroup(filters, "vendor");

        const belongsToVendor =
          vendors.length === 0 ||
          vendors.some((vendor) => vendor == item.parent.vendor?.id);

        if (!belongsToVendor) continue;

        const paymentAccountsFilters = getFilterGroup(
          filters,
          "payment_account"
        );

        const belongsToPaymentAccount =
          paymentAccountsFilters.length === 0 ||
          paymentAccountsFilters.some((paymentAccountId) => {
            if (!item.parent.paymentAccount) return false;
            return (
              paymentAccountId ===
              parseRefinementIdFromUrl(item.parent.paymentAccount)
            );
          });

        if (!belongsToPaymentAccount) continue;

        const { to, from } = getRangeFilter(filters);

        const belongsToDateRange =
          !to ||
          !from ||
          (to &&
            from &&
            item.parent.date &&
            isBetween(item.parent.date, from, to));

        if (!belongsToDateRange) continue;

        const accounts = getFilterGroup(filters, "account");
        const costCodes = getFilterGroup(filters, "cost_code");

        const belongsToAccount =
          (accounts.length === 0 && costCodes.length === 0) ||
          accounts.some((account) => account == item.account?.id);

        const belongsToCostCode =
          (costCodes.length === 0 && accounts.length === 0) ||
          costCodes.some((costCode) => costCode == item.item?.id);

        if (!(belongsToAccount || belongsToCostCode)) continue;

        items.push(item);
      }

      return items;
    }, [data, filters, hasExtraFilter, hasFilters]);

    const row = useMemo<TableRowAddon<BillableExpense>>(
      () => ({
        isVisible: (row) => searchResults.some((item) => item.id === row.id),
      }),
      [searchResults]
    );

    const isDisabled = useCallback(
      (row: BillableExpense) =>
        row.draw
          ? `This line has already been added to ${row.draw.docNumber}`
          : false,
      []
    );

    const select = useMemo<TableSelectAddon<BillableExpense>>(
      () => ({
        value: selected,
        onChange: onSelect,
        isDisabled,
      }),
      [selected, onSelect, isDisabled]
    );

    /**
     * @todo Remove this lint error as soon as we start using this var.
     */
    const hasSelections = selected.length > 0; // eslint-disable-line

    const columns = useMemo<TableColumn<BillableExpense>[]>(
      () => [
        {
          id: "doc_number",
          sortable: "asc",
          name: "Ref #",
          visibility: "always-visible",
          render: (row) =>
            row.parent.docNumber ? (
              <Text
                as={Link}
                rel="noreferrer"
                href={getTransactionRoute(row.parent.url)}
                target="_blank"
                truncate={2}
              >
                <Text as="span" size="sm">
                  #{row.parent.docNumber}
                </Text>
              </Text>
            ) : (
              "Missing ref #"
            ),
        },
        {
          id: "vendor__display_name",
          sortable: "asc",
          name: "Vendor name",
          width: 200,
          render: (row) => (
            <Text truncate={1}>
              {row.parent.vendor?.displayName ?? "Unknown vendor"}
            </Text>
          ),
        },
        {
          id: "description",
          name: "Description",
          width: 200,
          render: (row) => <Text truncate>{row.description || ""}</Text>,
          visibility: "hidden",
        },
        {
          id: "item_account",
          sortable: "asc",
          name: "Cost code / Account",
          width: 200,
          render: (row) => (
            <Text truncate>
              {row.item?.displayName ||
                row.account?.displayName ||
                "Unknown Cost code / Account"}
            </Text>
          ),
        },
        {
          id: "date",
          sortable: true,
          name: "Date",
          width: 130,
          render: (row) => formatDate(row.parent.date),
        },
        {
          id: "type",
          name: "Type",
          render: "parent.humanReadableType",
        },
        {
          id: "status",
          name: "Status",
          render: (row) => (
            <Tag
              color={row.parent.reviewStatus === "Paid" ? "success" : "neutral"}
            >
              {transformReviewStatusValueToLabel(row.parent.reviewStatus)}
            </Tag>
          ),
        },
        {
          id: "amount",
          sortable: true,
          name: "Cost",
          width: 140,
          textAlign: "right",
          render: (row) => formatCurrency(row.amount, CURRENCY_FORMAT),
        },
        /**
         * @todo Uncomment these lines when the feature is ready
         */
        // {
        //   id: "actions",
        //   textAlign: "right",
        //   width: 135,
        //   name: hasSelections ? (
        //     <Dropdown placement="bottom-end">
        //       <DropdownTrigger as={Button} size="sm">
        //         Actions <Icon name="ellipsis-vertical" variant="solid" />
        //       </DropdownTrigger>
        //       <DropdownList>
        //         <DropdownItem>Mark as undrawn</DropdownItem>
        //         <DropdownItem>Mark as drawn</DropdownItem>
        //         <DropdownItem>Mark as not eligible</DropdownItem>
        //       </DropdownList>
        //     </Dropdown>
        //   ) : (
        //     <Flex height="32px" align="center" justify="right">
        //       Actions
        //     </Flex>
        //   ),
        //   render: () => (
        //     <Dropdown placement="bottom-end">
        //       <DropdownTrigger
        //         as={Button}
        //         size="sm"
        //         color="neutral"
        //         variant="ghost"
        //         aria-label="Actions"
        //       >
        //         <Icon name="ellipsis-vertical" variant="solid" />
        //       </DropdownTrigger>
        //       <DropdownList>
        //         <DropdownItem>Mark as undrawn</DropdownItem>
        //         <DropdownItem>Mark as drawn</DropdownItem>
        //         <DropdownItem>Mark as not eligible</DropdownItem>
        //       </DropdownList>
        //     </Dropdown>
        //   ),
        // },
      ],
      []
    );

    const enhancedData = useMemo<BillableExpense[]>(() => {
      const items = data.slice();

      const direction = sortBy.startsWith("-") ? -1 : 1;

      if (sortBy.endsWith("doc_number")) {
        items.sort((a, b) => {
          const docNumberA =
            (direction === 1 ? a.parent?.docNumber : b.parent?.docNumber) ?? "";
          const docNumberB =
            (direction === 1 ? b.parent?.docNumber : a.parent?.docNumber) ?? "";
          return stringCompare(docNumberA, docNumberB);
        });
      } else if (sortBy.endsWith("amount")) {
        items.sort((a, b) => {
          const amountA = (direction === 1 ? a?.amount : b?.amount) ?? 0;
          const amountB = (direction === 1 ? b?.amount : a?.amount) ?? 0;
          return amountA - amountB;
        });
      } else if (sortBy.endsWith("vendor__display_name")) {
        items.sort((a, b) => {
          const vendorA =
            (direction === 1
              ? a.parent?.vendor?.displayName
              : b.parent?.vendor?.displayName) ?? "";
          const vendorB =
            (direction === 1
              ? b.parent?.vendor?.displayName
              : a.parent?.vendor?.displayName) ?? "";
          return stringCompare(vendorA, vendorB);
        });
      } else if (sortBy.endsWith("item_account")) {
        items.sort((a, b) => {
          const itemAccountA =
            (direction === 1
              ? a.item?.displayName || a.account?.displayName
              : b.item?.displayName || b.account?.displayName) ?? "";
          const itemAccountB =
            (direction === 1
              ? b.item?.displayName || b.account?.displayName
              : a.item?.displayName || a.account?.displayName) ?? "";
          return stringCompare(itemAccountA, itemAccountB);
        });
      } else if (sortBy.endsWith("date")) {
        items.sort((a, b) => {
          const dateA =
            (direction === 1 ? a.parent?.date : b.parent?.date) ?? null;
          const dateB =
            (direction === 1 ? b.parent?.date : a.parent?.date) ?? null;

          if (!dateA || !dateB) return 0;

          return dateA.getTime() - dateB.getTime();
        });
      }

      return items;
    }, [data, sortBy]);

    const onBatchApply = useEvent<BatchApplyObjectOnChangeHandler>(
      useEvent(async ({ value, fieldName }) => {
        if (!value) return;

        const previousSelected = selected;

        try {
          const { success } = await applyObject.execute({
            value,
            method:
              fieldName === "billable_status"
                ? "apply_billable_status_batch"
                : "apply_object_batch",
            fieldName,
          });

          await refetch();

          onSelect(previousSelected);

          toast.success(
            `${success} Transaction${success > 1 ? "s" : ""} updated!`
          );

          analytics.track("invoiceLineCostBatchActions", {
            value,
            action: fieldName,
            linesId: previousSelected.map((line) => line.id),
          });
        } catch (e) {
          analytics.track("invoiceLineCostBatchActionsError", {
            value,
            action: fieldName,
            linesId: previousSelected.map((line) => line.id),
          });
          handleErrors(e);
        }
      })
    );

    const onClearFilters = useEvent(() => setFilters({}));

    const onFiltersChange = useEvent((filters) => setFilters(filters));

    const enhancedOnSave = useEvent(async () => {
      const lines = selected.reduce((lines, billableExpenseLine) => {
        if (!isLine(billableExpenseLine)) return lines;

        return [
          ...lines,
          {
            item: billableExpenseLine.item?.url,
            itemAccount: billableExpenseLine.account?.url,
            amount: billableExpenseLine.amount,
            description: billableExpenseLine.description,
            transactionLine: billableExpenseLine.url,
            vendor: billableExpenseLine.parent.vendor?.url,
          },
        ];
      }, [] as InvoiceLineMutate[]);

      try {
        setIsSubmitting(true);
        await onSave(lines);
      } finally {
        setIsSubmitting(false);
      }

      analytics.track("invoiceAddLineFromCosts", {
        lines,
        invoiceId,
        jobId: job.id,
      });
    });

    const sort = useMemo(
      () => ({ value: sortBy, onChange: setSortBy }),
      [sortBy]
    );

    useEffect(() => {
      if (isFetching) onSelect([]);
    }, [isFetching, onSelect]);

    return (
      <>
        {applyObject.isLoading && <Loader position="fixed" />}
        <DialogHeader>
          <Flex gap="sm" direction="column">
            <Text>
              Select costs to include on this{" "}
              {INVOICE_STRINGS.INVOICE.toLowerCase()}
            </Text>
            <Text size="md" weight="regular">
              Job: {job.name}
            </Text>
          </Flex>
        </DialogHeader>
        <DialogContent>
          <Flex
            gap="lg"
            height="525px"
            minWidth="1100px"
            direction="column"
            justify="flex-start"
          >
            <TableProvider id="invoice-line-cost-dialog-table">
              <Flex gap="md" direction="column">
                <TableFilterControls
                  filters={rawFilters}
                  extraData={extraData}
                  withFilterTags
                  withDateFilter
                  dateProps={{ placeholder: "Filter by date created" }}
                  includeFilters={[
                    "vendors",
                    {
                      name: "costCodes",
                      showDisableAsEnabled: true,
                      active: true,
                    },
                    "accounts",
                  ]}
                  onFiltersChange={onFiltersChange}
                >
                  {hasFilters && (
                    <Button onClick={onClearFilters}>Clear filters</Button>
                  )}

                  <Flex gap="md" grow justify="flex-end">
                    <TableConfigurationButton />
                    {selected.length > 0 && (
                      <Dropdown placement="bottom-end">
                        <DropdownTrigger as={Button} color="primary">
                          Actions
                          <Icon name="ellipsis-vertical" variant="solid" />
                        </DropdownTrigger>
                        <DropdownList>
                          <DropdownItem>
                            <BatchApplyObject
                              onChange={onBatchApply}
                              includeVendors={false}
                              includeBillableStatus
                              accountFilters={{ only_line_item_accounts: true }}
                            />
                          </DropdownItem>
                        </DropdownList>
                      </Dropdown>
                    )}
                  </Flex>
                </TableFilterControls>
              </Flex>
              <Table
                size="sm"
                row={row}
                sort={sort}
                data={enhancedData}
                select={select}
                loading={isLoading || isFetching}
                columns={columns}
                maxHeight={`${hasTags ? TABLE_HEIGHT - 40 : TABLE_HEIGHT}px`}
                emptyState={{
                  title: "There are no transactions that match these filters",
                }}
              />
            </TableProvider>
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Flex width="full">
            <Button block variant="text" color="neutral" onClick={onCancel}>
              Cancel
            </Button>
          </Flex>
          <Tooltip
            as={Flex}
            width="full"
            message={
              selected.length > 0
                ? undefined
                : `Select items from the table to add to the ${INVOICE_STRINGS.INVOICE.toLowerCase()}`
            }
            placement="left"
          >
            <Button
              block
              disabled={selected.length === 0 || isSubmitting}
              onClick={enhancedOnSave}
            >
              {isSubmitting ? (
                <Loader />
              ) : (
                `Add to ${INVOICE_STRINGS.INVOICE.toLowerCase()}`
              )}
            </Button>
          </Tooltip>
        </DialogFooter>
      </>
    );
  }
);

InvoiceLineCostDialog.displayName = "InvoiceLineCostDialog";
