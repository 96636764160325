import React, {
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { autoUpdate, computePosition, flip } from "@floating-ui/dom";
import type { Editor } from "@tiptap/core";
import { Bold as ExtensionBold } from "@tiptap/extension-bold";
import { BulletList as ExtensionBulletList } from "@tiptap/extension-bullet-list";
import { Document as ExtensionDocument } from "@tiptap/extension-document";
import { Gapcursor as ExtensionGapCursor } from "@tiptap/extension-gapcursor";
import { HardBreak as ExtensionHardBreak } from "@tiptap/extension-hard-break";
import { History as ExtensionHistory } from "@tiptap/extension-history";
import { Italic as ExtensionItalic } from "@tiptap/extension-italic";
import { ListItem as ExtensionListItem } from "@tiptap/extension-list-item";
import { Mention as ExtensionMention } from "@tiptap/extension-mention";
import { OrderedList as ExtensionOrderedList } from "@tiptap/extension-ordered-list";
import { Paragraph as ExtensionParagraph } from "@tiptap/extension-paragraph";
import { Placeholder as ExtensionPlaceholder } from "@tiptap/extension-placeholder";
import { Text as ExtensionText } from "@tiptap/extension-text";
import { TextAlign as ExtensionTextAlign } from "@tiptap/extension-text-align";
import { Underline as ExtensionUnderline } from "@tiptap/extension-underline";
import { PluginKey } from "@tiptap/pm/state";
import type { EditorProps } from "@tiptap/pm/view";
import {
  type AnyExtension,
  EditorContent,
  Extension,
  mergeAttributes,
  ReactRenderer,
  useEditor,
} from "@tiptap/react";
import { type SuggestionProps } from "@tiptap/suggestion";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { useIsDragging } from "../../hooks/use-is-dragging";
import { mergeRefs } from "../../utils/merge-refs";
import { showOpenFilePicker } from "../../utils/show-open-file-picker";
import { suffixify } from "../../utils/suffixify";
import { FieldMessage } from "../field-message";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Label } from "../label";
import { Loader } from "../loader";
import { useProvider } from "../provider/provider-context";
import { Tag } from "../tag";
import { Text } from "../text/text";
import { toast } from "../toast";
import { Tooltip } from "../tooltip";
import { Wrapper } from "../wrapper";

import { TextareaProvider } from "./textarea-context";
import { SuggestionList } from "./textarea-suggestion-list";
import { Toolbar } from "./textarea-toolbar";
import type {
  AttachmentAddonFile,
  MentionDataItem,
  SuggestionListProps,
  SuggestionListRef,
  TextareaProps,
  TextareaRef,
} from "./types";
import {
  br2nl,
  getErrorMessageFromFile,
  htmlToValue,
  isValidAcceptType,
  search,
  valueToHtml,
} from "./utils";
import styles from "./textarea.module.css";

const ATTRIBUTES_TO_RESET = [
  "form",
  "autofocus",
  "data-testid",
  "aria-labelledby",
  "aria-describedby",
];

const ExtensionSubmit = Extension.create({
  name: "submit",
  addKeyboardShortcuts() {
    const submit = () => {
      const formId = this.editor.view.dom.getAttribute("form");

      const form = formId
        ? document.getElementById(formId)
        : this.editor.view.dom.closest("form");

      if (form) (form as HTMLFormElement).requestSubmit();

      return true;
    };

    return { "Cmd-Enter": submit, "Ctrl-Enter": submit };
  },
});

export const Textarea = forwardRef<TextareaRef, TextareaProps>(
  (
    {
      id,
      size = "md",
      form,
      label,
      style,
      value,
      onBlur,
      onKeyUp,
      onFocus,
      mention,
      wysiwyg,
      disabled,
      required,
      onChange,
      onKeyDown,
      minHeight,
      maxHeight,
      autoFocus,
      className,
      attachment = {},
      messageVariant = "relative",
      hintMessage,
      renderAfter,
      placeholder,
      errorMessage,
      helperMessage,
      "data-testid": testId,
      "aria-labelledby": labelledBy,
    },
    ref
  ) => {
    const editorRef = useRef<Editor>();

    const internalId = useId();

    const enhancedId = id ?? internalId;

    const internalRef = useRef<TextareaRef>(null);

    const editorWrapperRef = useRef<HTMLDivElement>();

    const [isOver, setIsOver] = useState(false);

    const [files, setFiles] = useState<AttachmentAddonFile[]>([]);

    const [internalValue, setInternalValue] = useState("");

    const [updateCounter, forceUpdate] = useReducer((s) => s + 1, 0);

    const enhancedValue = valueToHtml(value ?? internalValue);

    const { autoFocus: providerAutoFocus } = useProvider();

    const enhancedAutoFocus = !!(autoFocus && providerAutoFocus);

    const enhancedStyle = {
      ...style,
      ...(maxHeight ? { "--textarea-max-height": `${maxHeight}px` } : {}),
      ...(minHeight ? { "--textarea-min-height": `${minHeight}px` } : {}),
    };

    const attachmentOnChange = useMemo(
      () => attachment?.onChange,
      [attachment.onChange]
    );

    const attachmentUploadFile = useMemo(
      () => attachment?.uploadFile,
      [attachment.uploadFile]
    );

    const attachmentLimit = useMemo(
      () =>
        typeof attachment?.multiple === "number"
          ? attachment?.multiple
          : attachment?.multiple
            ? Infinity
            : 1,
      [attachment.multiple]
    );

    const attachmentEnabled = !!attachmentOnChange && !disabled;

    const attachmentMaxSizeValidation = useMemo(
      () => attachment?.maxSizeValidation ?? "individual",
      [attachment.maxSizeValidation]
    );

    const validateAttachmentMaxSize = useCallback(
      (size: number) =>
        !(
          attachment.maxSize !== undefined &&
          size / 1024 > attachment.maxSize * 1024
        ),
      [attachment.maxSize]
    );

    const asyncSetFiles = useEvent((nextFiles: File[]) => {
      const enhancedFiles = nextFiles as AttachmentAddonFile[];

      if (enhancedFiles.length + files.length > attachmentLimit) {
        return toast.error(
          attachmentLimit === 1
            ? "You can only upload one file"
            : `You can only upload up to ${attachmentLimit} files`
        );
      }

      if (attachmentMaxSizeValidation === "total") {
        const previousFilesTotalSize = files.reduce(
          (acc, file) => acc + file.size,
          0
        );
        const nextFilesTotalSize = nextFiles.reduce(
          (acc, file) => acc + file.size,
          0
        );
        const totalSize = previousFilesTotalSize + nextFilesTotalSize;

        if (!validateAttachmentMaxSize(totalSize)) {
          return toast.error(
            `Total sum of files size must be under ${attachment.maxSize}MB`
          );
        }
      }

      for (const file of enhancedFiles) {
        const errorMessages: string[] = [];

        if (
          attachmentMaxSizeValidation === "individual" &&
          !validateAttachmentMaxSize(file.size)
        ) {
          errorMessages.push(`File size must be under ${attachment.maxSize}MB`);
        }

        if (attachment.accept && !isValidAcceptType(file, attachment.accept)) {
          errorMessages.push("File type not supported");
        }

        if (errorMessages.length) {
          file.meta = { message: errorMessages.join("\n") };
          file.status = "error";
          continue;
        }

        file.status =
          file.status === "success"
            ? "success"
            : attachmentUploadFile
              ? "pending"
              : "success";
      }

      if (enhancedFiles.length) {
        setFiles((files) => [...files, ...enhancedFiles]);

        if (attachmentUploadFile) {
          enhancedFiles.forEach(async (file) => {
            if (file.status !== "pending") return;

            try {
              const result = await attachmentUploadFile(file);

              setFiles((files) => {
                let found = false;

                for (const item of files) {
                  if (item.name === file.name) {
                    found = true;
                    item.meta = result;
                    item.status = "success";
                  }
                }

                if (!found) return files;

                return [...files];
              });
            } catch (e) {
              setFiles((files) => {
                let found = false;

                for (const item of files) {
                  if (item.name === file.name) {
                    found = true;
                    item.meta = e;
                    item.status = "error";
                  }
                }

                if (!found) return files;

                return [...files];
              });
            }
          });
        }
      }
    });

    const curriedRemoveFile = useEvent((index: number) => () => {
      setFiles((files) => files.filter((_, i) => i !== index));
    });

    const setFilesFromPicker = useCallback(async () => {
      asyncSetFiles(
        await showOpenFilePicker({
          accept: attachment.accept,
          multiple: attachmentLimit > 1,
        })
      );
    }, [asyncSetFiles, attachment.accept, attachmentLimit]);

    const onDrop = useEvent((e: DragEvent) => {
      if (!isOver || !e.dataTransfer) return;

      asyncSetFiles([...e.dataTransfer.files]);
      setIsOver(false);
    });

    const onOver = useEvent((e: DragEvent) => {
      setIsOver(
        !!editorWrapperRef.current &&
          (editorWrapperRef.current === e.target ||
            editorWrapperRef.current?.contains(e.target as Node))
      );
    });

    const isDragging = useIsDragging({
      onDrop,
      onOver,
      enabled: attachmentEnabled,
    });

    const getExtensions = () => {
      const mentionsExtensions: (typeof ExtensionMention)[] = [];

      if (mention) {
        (Array.isArray(mention) ? mention : [mention]).forEach((mention) => {
          mentionsExtensions.push(
            ExtensionMention.extend({
              name: `mention-${mention.trigger}`,
              addStorage: () => ({}),
            }).configure({
              renderHTML({ options, node }) {
                const { data, generateMentionProps } = mention;

                const item =
                  (data ?? []).find((item) => item.value === node.attrs.id) ||
                  ({ ...node.attrs, value: node.attrs.id } as MentionDataItem);

                let style = "";
                let content = `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;

                if (item && generateMentionProps) {
                  const { color, background, children } =
                    generateMentionProps?.(item) ?? {};

                  style += `--textarea-mention-color: ${color ?? "var(--color-brand-2)"};`;
                  style += `--textarea-mention-background: ${background ?? "var(--color-brand-1)"};`;

                  if (children) {
                    content = children;
                  }
                }

                return [
                  "span",
                  mergeAttributes(options.HTMLAttributes, { style }),
                  content,
                ];
              },
              suggestion: {
                char: mention.trigger,
                allowedPrefixes: [" ", "(", "[", "{", "<", "'", '"'],
                allowSpaces: true,
                items: ({ query }) => {
                  const { data, loading } = mention;

                  const searchFn = mention.search || search;

                  return searchFn(loading ? [] : (data ?? []), query);
                },
                render() {
                  let cleanup: ReturnType<typeof autoUpdate>;

                  let component: ReactRenderer<
                    SuggestionListRef,
                    SuggestionListProps
                  >;

                  const searchFn = mention.search || search;

                  const getProps = (props: SuggestionProps<any>) => {
                    const { data, onAdd, placement, renderSuggestion } =
                      props.editor.storage[`mention-${mention.trigger}`] ?? {};

                    return {
                      ...props,
                      items: searchFn(data ?? props.items ?? [], props.query),
                      onAdd,
                      render: renderSuggestion,
                      placement,
                    };
                  };

                  const updatePosition = async () => {
                    const { placement } = mention;

                    const el = component.element as HTMLElement;

                    const { x, y } = await computePosition(
                      component.props.decorationNode,
                      el,
                      {
                        placement: `${placement ?? "bottom"}-start`,
                        middleware: [flip()],
                      }
                    );

                    el.style.top = `${y}px`;
                    el.style.left = `${x}px`;
                    el.style.visibility = "visible";
                  };

                  const updateMethods = (props: SuggestionProps<any>) => {
                    if (!props.editor.storage[`mention-${mention.trigger}`]) {
                      return;
                    }

                    props.editor.storage[
                      `mention-${mention.trigger}`
                    ].rerender = () => {
                      const enhancedProps = getProps(props);
                      component.updateProps(enhancedProps);
                      updatePosition();
                    };

                    props.editor.storage[`mention-${mention.trigger}`].hide =
                      () => {
                        component.ref?.hide();
                      };
                  };

                  return {
                    onStart: (props) => {
                      const enhancedProps = getProps(props);

                      component = new ReactRenderer(SuggestionList, {
                        props: enhancedProps,
                        attrs: { role: "listbox", tabindex: "-1" },
                        editor: props.editor, // eslint-disable-line react/prop-types
                        className: styles["suggestion-list"],
                      });

                      document.body.appendChild(component.element);

                      updatePosition();

                      cleanup = autoUpdate(
                        component.props.decorationNode,
                        component.element as HTMLElement,
                        () => updatePosition()
                      );
                      updateMethods(props);
                    },
                    onUpdate: (props) => {
                      const enhancedProps = getProps(props);
                      component.updateProps(enhancedProps);
                      updateMethods(props);
                      updatePosition();
                    },
                    onKeyDown: (props) =>
                      props.event.key === "Escape" // eslint-disable-line react/prop-types
                        ? (component.ref?.hide() ?? true)
                        : (component.ref?.onKeyDown(props.event) ?? true), // eslint-disable-line react/prop-types
                    onExit: () => {
                      cleanup?.();
                      component?.destroy();
                      component?.element.remove();
                      return true;
                    },
                  };
                },
                pluginKey: new PluginKey(
                  `mention-${mention.trigger}-suggestion`
                ),
                decorationClass: styles["trigger"],
              },
              HTMLAttributes: { class: styles["mention"] },
            })
          );
        });
      }

      let extensions: AnyExtension[] = [
        ExtensionDocument,
        ExtensionParagraph,
        ExtensionText,
        ExtensionHardBreak,
      ];

      if (wysiwyg) {
        extensions = [
          ...extensions,
          ExtensionBold,
          ExtensionItalic,
          ExtensionUnderline,
          ExtensionTextAlign.configure({ types: ["paragraph"] }),
          ExtensionBulletList,
          ExtensionOrderedList,
          ExtensionListItem,
        ];
      }

      return [
        ...extensions,
        ExtensionGapCursor,
        ExtensionHistory.configure({ newGroupDelay: 500 }),
        ExtensionPlaceholder.configure({
          placeholder: ({ editor }) => editor.storage.placeholder,
          showOnlyWhenEditable: false,
        }),
        ...mentionsExtensions,
        ExtensionSubmit,
      ];
    };

    const enhancedLabelledBy =
      (labelledBy ?? label) ? suffixify(enhancedId, "label") : undefined;

    const enhancedOnBlur = useEvent((e: FocusEvent) => {
      for (const key in editorRef.current?.storage ?? {}) {
        if (key.startsWith("mention-")) {
          editorRef.current!.storage[key].hide?.();
        }
      }

      onBlur?.(e);
    });

    const editorProps = useMemo<Partial<EditorProps>>(
      () => ({
        attributes: {
          id: enhancedId,
          class: styles["editor"],
          "aria-invalid": String(!!errorMessage),
          "aria-required": String(!!required),
          "aria-disabled": String(!!disabled),
          ...(form ? { form } : {}),
          ...(testId ? { "data-testid": testId } : {}),
          ...(placeholder ? { "aria-placeholder": placeholder } : {}),
          ...(enhancedLabelledBy
            ? { "aria-labelledby": enhancedLabelledBy }
            : {}),
          ...(enhancedAutoFocus ? { autofocus: "true" } : {}),
          ...(errorMessage || helperMessage
            ? { "aria-describedby": suffixify(enhancedId, "message") }
            : {}),
        },
        handleDOMEvents: {
          blur: (_, e) => enhancedOnBlur?.(e),
          keyup: (_, e) => onKeyUp?.(e),
          focus: (_, e) => onFocus?.(e),
          keydown: (_, e) => onKeyDown?.(e),
        },
      }),
      [
        form,
        testId,
        onKeyUp,
        onFocus,
        required,
        disabled,
        onKeyDown,
        enhancedId,
        placeholder,
        errorMessage,
        helperMessage,
        enhancedOnBlur,
        enhancedAutoFocus,
        enhancedLabelledBy,
      ]
    );

    /**
     * `useEditor` hook from `@tiptap/react` package does not rerender when props change.
     * So we need to manage it manually thru `useEffects`, so these props we set here are
     * static for them.
     */
    const editor = useEditor(
      {
        content: enhancedValue,
        onUpdate: ({ editor }) => {
          const html = htmlToValue(editor.getHTML());
          const enhancedValue = wysiwyg ? html : br2nl(html);
          setInternalValue(enhancedValue);
          onChange?.(enhancedValue);
          editorRef.current = editor;
        },
        editable: !disabled && !isDragging,
        autofocus: enhancedAutoFocus,
        extensions: getExtensions(),
        editorProps,
        onCreate: ({ editor }) => {
          editorRef.current = editor;
        },
        onDestroy: () => {
          editorRef.current = undefined;
        },
      },
      [updateCounter]
    );

    const setEditorContent = useCallback((value: string) => {
      if (!editorRef.current) return;

      const { from, to } = editorRef.current.state.selection;

      editorRef.current.commands.setContent(value, false, {
        preserveWhitespace: true,
      });

      const newDocSize = editorRef.current.state.doc.content.size;

      editorRef.current.commands.setTextSelection({
        from: Math.min(from, newDocSize),
        to: Math.min(to, newDocSize),
      });
    }, []);

    useEffect(() => {
      if (!editorRef.current) return;

      const nextAttributes =
        typeof editorProps.attributes === "object"
          ? editorProps.attributes
          : {};

      const prevAttributes =
        typeof editorRef.current.options.editorProps.attributes === "object"
          ? editorRef.current.options.editorProps.attributes
          : {};

      ATTRIBUTES_TO_RESET.forEach((attr) => {
        if (nextAttributes[attr] !== prevAttributes[attr]) {
          delete prevAttributes[attr];
        }
      });

      editorRef.current.setOptions({ editorProps });
    }, [editorProps]);

    useEffect(() => {
      if (value === undefined) return;

      const enhancedValue = valueToHtml(value);

      if (value === internalValue) return;

      setInternalValue(enhancedValue);
      setEditorContent(enhancedValue);
    }, [value, internalValue, setEditorContent]);

    useEffect(() => {
      if (!editor) return;

      editor.storage.placeholder = placeholder ?? "";
      editor.setOptions();
    }, [editor, placeholder]);

    /**
     * TipTap unfortunately does not support changing the extensions dynamically, and
     * our mention is dynamically changing since for example a collection of users could
     * be loading. To workaround this we need to manually update the storage and set the
     * options again.
     *
     * The logic here is basically
     * - If there is no mention and we previous had a mention, remove the mention from the storage and force update
     * - If there is no mention, do nothing
     * - If there is a mention and has a new mention, update the storage and force update
     * - If there is a mention and it is the same (so we are updating the data), only update the storage
     */
    useEffect(() => {
      if (!editor) return;

      const hasPreviousMention = Object.keys(editor.storage).some((key) =>
        key.startsWith("mention-")
      );

      if (!mention) {
        if (hasPreviousMention) {
          for (const key of Object.keys(editor.storage)) {
            if (key.startsWith("mention-")) {
              delete editor.storage[key];
            }
          }

          forceUpdate();
        }

        return;
      }

      let hasNewMention = false;

      for (const item of Array.isArray(mention) ? mention : [mention]) {
        if (!editor.storage[`mention-${item.trigger}`]) {
          hasNewMention = true;
        }

        const rerender = (editor.storage[`mention-${item.trigger}`] ?? {})
          .rerender;
        editor.storage[`mention-${item.trigger}`] = item;
        requestAnimationFrame(() => rerender?.());
      }

      editor!.setOptions();

      if (hasNewMention) forceUpdate();
    }, [editor, mention]);

    /**
     * TipTap does not support changing the extensions dynamically, so we need to
     * force update the editor when the wysiwyg prop changes.
     */
    useEffect(() => {
      if (editorRef.current) forceUpdate();
    }, [wysiwyg]);

    useEffect(() => {
      editorRef.current?.setEditable(!disabled && !isDragging);
    }, [disabled, isDragging]);

    useEffect(() => {
      attachmentOnChange?.(files);
    }, [files, attachmentOnChange]);

    return (
      <div
        style={enhancedStyle}
        className={cn(className, styles["wrapper"], {
          [styles["-over"]]: isOver,
          [styles["-error"]]: errorMessage,
          [styles[`-${size}`]]: size,
          [styles["-disabled"]]: disabled,
          [styles["-dragging"]]: isDragging,
        })}
        data-testid={suffixify(testId, "wrapper")}
      >
        {label && (
          <Label
            id={suffixify(enhancedId, "label")}
            htmlFor={enhancedId}
            variant={errorMessage && !disabled ? "error" : "neutral"}
            onClick={() =>
              (
                document.querySelector(
                  `[id="${enhancedId}"]`
                ) as HTMLElement | null
              )?.focus()
            }
            required={required}
            hintMessage={hintMessage}
            data-testid={suffixify(testId, "label")}
          >
            {label}
          </Label>
        )}
        <TextareaProvider
          value={{
            editor,
            wysiwyg,
            setFilesFromPicker: attachmentEnabled
              ? setFilesFromPicker
              : undefined,
          }}
        >
          <EditorContent
            ref={(el) => {
              if (!el) return;

              editorWrapperRef.current = el;

              const editorEl =
                el.querySelector<TextareaRef>("[contenteditable]");

              if (!editorEl) return;

              /**
               * For some reason useImperativeHandle does not work with
               * the editor element so we need to set the ref manually here.
               */
              editorEl.reset = () => {
                editorEl.clearFiles();
                editorEl.clearValue();
              };
              editorEl.isEmpty = () =>
                editorRef?.current?.getText().replace(/\s/g, "").length === 0;
              editorEl.getFiles = () => files;
              editorEl.hasFiles = (status) => {
                const enhancedFiles = status
                  ? files.filter((file) =>
                      Array.isArray(status)
                        ? status.includes(file.status)
                        : file.status === status
                    )
                  : files;

                return enhancedFiles.length > 0;
              };
              editorEl.setFiles = asyncSetFiles;
              editorEl.getValue = () => {
                const html = htmlToValue(editorRef?.current?.getHTML() ?? "");
                return wysiwyg ? html : br2nl(html);
              };
              editorEl.setValue = (value) => {
                setInternalValue(value);
                setEditorContent(value);
              };
              editorEl.clearFiles = () => setFiles([]);
              editorEl.clearValue = () => editorEl.setValue("");

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              mergeRefs(ref, internalRef)(editorEl);
            }}
            editor={editor}
            className={styles["inner"]}
          >
            <Toolbar />
            {attachmentEnabled && (
              <div className={styles["attachment-droppable-area"]} />
            )}
          </EditorContent>
        </TextareaProvider>
        <FieldMessage
          id={suffixify(enhancedId, "message")}
          show={!!(errorMessage || helperMessage)}
          color={errorMessage ? "error" : "neutral"}
          variant={messageVariant}
          data-testid={suffixify(testId, "message")}
        >
          {errorMessage || helperMessage}
        </FieldMessage>
        <Wrapper
          when={!!renderAfter}
          render={(children) => (
            <div
              className={cn(styles["extra"], {
                [styles["-margin"]]: messageVariant !== "relative",
              })}
            >
              {children}
            </div>
          )}
        >
          <>
            {files.length > 0 && (
              <div className={styles["file-list"]}>
                {files.map((file, index) => {
                  const errorMessage = getErrorMessageFromFile(file);

                  return (
                    <Tag
                      key={index}
                      variant="pill"
                      onClick={() =>
                        window.open(URL.createObjectURL(file), "_blank")
                      }
                      color={file.status === "error" ? "error" : "neutral"}
                      onRemove={
                        file.status !== "pending"
                          ? curriedRemoveFile(index)
                          : undefined
                      }
                    >
                      <Tooltip as={Flex} message={errorMessage} gap="md">
                        <Icon
                          size="sm"
                          name={
                            file.status === "error"
                              ? "exclamation-triangle"
                              : file.type.includes("image")
                                ? "image"
                                : "file"
                          }
                          variant="light"
                          color={
                            file.status === "error"
                              ? "error-200"
                              : "neutral-600"
                          }
                        />
                        <Text truncate={{ tooltip: !errorMessage }}>
                          {file.name}
                        </Text>
                        {file.status === "pending" && <Loader />}
                      </Tooltip>
                    </Tag>
                  );
                })}
              </div>
            )}
            {renderAfter?.({
              setFilesFromPicker: attachmentEnabled
                ? setFilesFromPicker
                : undefined,
            })}
          </>
        </Wrapper>
      </div>
    );
  }
);

Textarea.displayName = "TextArea";
