import React, {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  ComboBox,
  type ComboBoxRef,
  CurrencyField,
  dialog,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  NumberField,
  Table,
  type TableColumn,
  type TableHeaderAddon,
  type TableProps,
  Text,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDeepMemo, useEvent, useForm } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { useTriggerSyncMutation, useUnlinkMutation } from "@api/quickbooks";
import { updateRealm } from "@api/realms";
import { CostCodeAccountComboBox } from "@components/cost-code-account-combobox";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";
import { QuickBooksButton } from "@components/quickbooks-button";
import { QuickBooksIcon } from "@components/quickbooks-icon/quickbooks-icon";
import { useIntegrationType } from "@hooks/use-integration-type";
import { useUsersSimplified } from "@hooks/useUsersSimplified";
import { LienWaiverTemplatesList } from "@lien-waiver/components";
import { useClientAction, useClientInfo, useUserInfo } from "@store/user";
import { type Client, toggleChatVisibility } from "@store/user/slice";
import * as analytics from "@utils/analytics";
import { api } from "@utils/api";
import { z } from "zod";

type Integration = {
  id: string;
  name: string;
  image: ReactNode;
};

const schema = z.object({
  name: z.string().min(1),
  salesTax: z.string(),
});

export const General = () => {
  const dispatch = useDispatch();

  const defaultReviewerRef = useRef<ComboBoxRef>(null);

  const users = useUsersSimplified({ filters: { is_staff: false } });

  const [unlinkMutation] = useUnlinkMutation();

  const [triggerSyncMutation] = useTriggerSyncMutation();

  const { client, realmId } = useClientInfo();
  const { canManageLienWaiverTemplate } = useUserInfo();

  const getInitialValues = useCallback(() => {
    const {
      settings: {
        sales_tax_account,
        sales_tax_item,
        default_reviewer,
        ...settings
      },
      ...rest
    } = client as Client;

    const defaultReviewerUrl = default_reviewer?.url || null;

    return {
      ...rest,
      salesTax: sales_tax_account?.url || sales_tax_item?.url || "",
      settings: {
        default_reviewer: defaultReviewerUrl,
        default_reviewer_checked: !!defaultReviewerUrl,
        ...settings,
      },
    };
  }, [client]);

  const initialValues = useDeepMemo(
    () => getInitialValues(),
    [getInitialValues]
  );

  const integrationType = useIntegrationType();

  const { reloadClients } = useClientAction();

  const { reset, ...form } = useForm({
    schema,
    initialValues,
  });
  const save = useEvent(async () => {
    if (!form.isDirty || !form.isValid) return;

    const { id, name, salesTax, settings } = form.values;

    const salesTaxItem = salesTax.includes("/items/") ? salesTax : null;
    const salesTaxAccount = salesTax.includes("/accounts/") ? salesTax : null;

    try {
      await api.put(`/api/clients/${id}/`, {
        name,
        sales_tax_account: salesTaxAccount,
        sales_tax_item: salesTaxItem,
        is_billable_default_bill: settings.is_billable_default_bill,
        is_billable_default_expense: settings.is_billable_default_expense,
        reset_approval_on_edit: settings.reset_approval_on_edit,
        should_collapse_bill_lines: settings.should_collapse_bill_lines,
        should_collapse_expense_lines: settings.should_collapse_expense_lines,
        can_set_bill_payment_minimum: settings.can_set_bill_payment_minimum,
        bill_payment_minimum_for_lien_waiver:
          settings.can_set_bill_payment_minimum
            ? settings.bill_payment_minimum_for_lien_waiver
            : null,
        can_delay_payment_until_lien_waiver_is_signed:
          settings.can_delay_payment_until_lien_waiver_is_signed,
        default_reviewer: settings.default_reviewer_checked
          ? settings.default_reviewer
          : null,
        ai_chat_enabled: settings.ai_chat_enabled,
        should_remind_for_expenses: settings.should_remind_for_expenses,
        reminder_period_hours: settings.reminder_period_hours,
      });

      toast.success("Workspace updated");

      reloadClients();
    } catch (e) {
      handleErrors(e);
    }
  });

  const openChat = useCallback(() => {
    dispatch(toggleChatVisibility(true));
    analytics.track("chatOpen", { source: "general-settings" });
  }, [dispatch]);

  const onChangeDefaultReviewer = useEvent((value: boolean) => {
    if (value) return defaultReviewerRef.current?.focus({ show: true });

    form.setValue("settings.default_reviewer", null);
    save();
  });

  /**
   * @todo enable it as soon as we found a way to validate if user connected with the correct QB account
   */
  const isConnectToQuickBooksEnabled = false;

  const connectToQuickBooks = useEvent(() => {
    if (isConnectToQuickBooksEnabled) {
      window.location.replace(`/qbo/oauth_login/?realm_id=${realmId}`);
    } else {
      openChat();
    }
  });

  const curriedRemoveIntegration = useCallback(
    (integration: Integration) => () => {
      if (integration.id === "QBDT") return openChat();

      const handler = async () => {
        if (integration.id === "QBO") {
          await unlinkMutation().unwrap();
        }

        await reloadClients();
      };

      dialog.confirmation({
        title: `Remove ${integration.name} integration?`,
        action: {
          primary: {
            color: "error",
            onClick: handler,
            children: "Remove integration",
          },
        },
      });
    },
    [openChat, reloadClients, unlinkMutation]
  );

  const curriedTriggerSync = useCallback(
    (integration: Integration) => async () => {
      if (integration.id === "QBDT") {
        if (realmId === null) return;
        toast.success(
          `Next Quickbooks Desktop sync will be in "Force Pull All"
           mode. To trigger it, use the web connector.`
        );
        await updateRealm(realmId, "FORCE_PULL_ALL");
        return;
      }

      await triggerSyncMutation().unwrap();
      toast.success("Sync triggered. It may take a few minutes to complete.");
    },
    [triggerSyncMutation, realmId]
  );

  const integrationsHeader = useMemo<TableHeaderAddon>(
    () => ({ hide: true }),
    []
  );

  const integrationsEmptyState = useMemo<TableProps<Integration>["emptyState"]>(
    () => ({
      title: "",
      action: (
        <QuickBooksButton onClick={connectToQuickBooks} variant="connect" />
      ),
      subtitle: "You currently have no integrations.",
    }),
    [connectToQuickBooks]
  );

  const integrationsData = useMemo(() => {
    const integrations: Integration[] = [];

    if (integrationType === "QBO") {
      integrations.push({
        id: "QBO",
        name: "QuickBooks Online",
        image: <QuickBooksIcon />,
      });
    } else if (integrationType === "QBDT") {
      integrations.push({
        id: "QBDT",
        name: "QuickBooks Desktop",
        image: <QuickBooksIcon />,
      });
    }

    return integrations;
  }, [integrationType]);

  const integrationsColumns = useMemo<TableColumn<Integration>[]>(
    () => [
      {
        id: "image",
        width: 72,
        render: (row) => row.image,
      },
      {
        id: "info",
        width: "fill",
        render: (row) => (
          <Text size="xl" weight="bold">
            {row.name}
          </Text>
        ),
      },
      {
        id: "actions",
        textAlign: "right",
        render: (row) => (
          <Dropdown>
            <DropdownTrigger
              as={Button}
              color="neutral"
              variant="text"
              aria-label="Actions"
            >
              <Icon name="ellipsis-vertical" variant="solid" />
            </DropdownTrigger>
            <DropdownList>
              <DropdownItem onClick={curriedRemoveIntegration(row)}>
                Remove integration
              </DropdownItem>
              {row.id === "QBDT" ? (
                <Tooltip
                  message="This option will pull every record from your Quickbooks Desktop account
                           on the next sync. This might take multiple hours to complete depending
                           on the size of your accounting records"
                  placement="left"
                >
                  <DropdownItem onClick={curriedTriggerSync(row)}>
                    Pull all records on next sync
                  </DropdownItem>
                </Tooltip>
              ) : (
                <DropdownItem onClick={curriedTriggerSync(row)}>
                  Trigger sync
                </DropdownItem>
              )}
            </DropdownList>
          </Dropdown>
        ),
      },
    ],
    [curriedRemoveIntegration, curriedTriggerSync]
  );

  useEffect(() => reset(getInitialValues()), [reset, getInitialValues]);

  return (
    <Main autoFocus>
      <MainHeader>
        <MainTitle>General</MainTitle>
        <MainSubtitle>Manage your company on Adaptive</MainSubtitle>
      </MainHeader>

      <MainContent>
        <Flex shrink={false} gap="5xl" direction="column">
          <Flex direction="column" as="form" gap="4xl" {...form.props}>
            <Flex maxWidth="450px" direction="column">
              <TextField
                label="Workspace name"
                {...form.register({
                  name: "name",
                  type: "string",
                  onBlur: save,
                })}
              />

              {client?.settings
                .is_sales_tax_account_or_item_setting_enabled && (
                <CostCodeAccountComboBox
                  label="Allocate sales tax to"
                  placeholder="Evenly across lines"
                  {...form.register({
                    name: "salesTax",
                    type: "string",
                    onChange: save,
                  })}
                />
              )}
            </Flex>
            <Flex gap="xl" direction="column">
              <Text weight="bolder">Bills</Text>
              <Checkbox
                label="Show every line item on bills"
                {...form.register({
                  name: "settings.should_collapse_bill_lines",
                  type: "inverse-boolean",
                  onChange: save,
                })}
              />
              <Checkbox
                label="If a user edits a bill, restart the approval workflow"
                {...form.register({
                  name: "settings.reset_approval_on_edit",
                  type: "boolean",
                  onChange: save,
                })}
              />
              <Checkbox
                label="Make all bill line items billable"
                {...form.register({
                  name: "settings.is_billable_default_bill",
                  type: "boolean",
                  onChange: save,
                })}
              />
            </Flex>
            <Flex gap="xl" direction="column">
              <Text weight="bolder">Receipts</Text>
              <Checkbox
                label="Show every line item on receipts"
                {...form.register({
                  name: "settings.should_collapse_expense_lines",
                  type: "inverse-boolean",
                  onChange: save,
                })}
              />
              <Checkbox
                label="Make all receipt line items billable"
                {...form.register({
                  name: "settings.is_billable_default_expense",
                  type: "boolean",
                  onChange: save,
                })}
              />
              <Flex gap="md" direction="column">
                <Checkbox
                  label="Set default reviewer"
                  {...form.register({
                    name: "settings.default_reviewer_checked",
                    type: "boolean",
                    onChange: onChangeDefaultReviewer,
                  })}
                />
                {form.values.settings.default_reviewer_checked && (
                  <Flex margin={["none", "none", "none", "28px"]} width="300px">
                    <ComboBox
                      ref={defaultReviewerRef}
                      data={users.data}
                      loading={users.status === "loading"}
                      messageVariant="hidden"
                      {...form.register({
                        name: "settings.default_reviewer",
                        type: "string",
                        onChange: save,
                      })}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
            {canManageLienWaiverTemplate && (
              <Flex gap="lg" wrap align="center">
                <Flex gap="xl" direction="column" grow>
                  <Text weight="bolder">Lien waivers</Text>
                  <Flex gap="xl" direction="column">
                    <Checkbox
                      label="Set bill payment minimum"
                      {...form.register({
                        name: "settings.can_set_bill_payment_minimum",
                        type: "boolean",
                        onChange: save,
                      })}
                      hintMessage={`All bill payments below this $ amount will\nbe automatically set to: “Lien waiver not required”`}
                    />
                    {form.values.settings.can_set_bill_payment_minimum && (
                      <Flex
                        maxWidth="215px"
                        padding={["none", "none", "none", "2xl"]}
                      >
                        <CurrencyField
                          required
                          placeholder="0.00"
                          messageVariant="hidden"
                          align="right"
                          {...form.register({
                            name: "settings.bill_payment_minimum_for_lien_waiver",
                            type: "currency",
                            onBlur: save,
                          })}
                        />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                <LienWaiverTemplatesList />
              </Flex>
            )}
            {form.values.settings.ai_chat_enabled && (
              <Flex gap="lg" wrap align="center">
                <Flex gap="xl" direction="column" grow>
                  <Text weight="bolder">AI reminders 🤖</Text>
                  <Flex gap="xl" direction="column">
                    <Checkbox
                      label="Automatically send AI reminders"
                      {...form.register({
                        name: "settings.should_remind_for_expenses",
                        type: "boolean",
                        onChange: save,
                      })}
                      hintMessage={`Receipts and transactions that are more than this many hours old will receive AI reminders`}
                    />
                    {form.values.settings.should_remind_for_expenses && (
                      <Flex
                        maxWidth="215px"
                        padding={["none", "none", "none", "2xl"]}
                      >
                        <NumberField
                          label="Reminder period (hours)"
                          messageVariant="hidden"
                          {...form.register({
                            name: "settings.reminder_period_hours",
                            type: "number",
                            onBlur: save,
                          })}
                        />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )}
            <Flex gap="xl" direction="column" width="full">
              <Text weight="bolder">Integrations</Text>
              <Table
                id="general-integrations-table"
                size="sm"
                data={integrationsData}
                header={integrationsHeader}
                columns={integrationsColumns}
                bordered={false}
                emptyState={integrationsEmptyState}
              />
            </Flex>
          </Flex>
        </Flex>
      </MainContent>
    </Main>
  );
};
