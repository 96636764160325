import React, { type PropsWithChildren, useState } from "react";
import { MenuProvider, type MenuStoreState } from "@ariakit/react";

import { DropdownContext } from "./dropdown-context";

export type DropdownProps = PropsWithChildren<{
  show?: boolean;
  flip?: boolean;
  trigger?: "hover" | "click";
  listSize?: number;
  maxWidth?: number | "max-content";
  onChange?: (show: boolean) => void;
  placement?: MenuStoreState["placement"];
  initialShow?: boolean;
  hideOnClick?: boolean;
}>;

export type DropdownInternalValue = Record<string, string | string[]>;

export type DropdownSetSetValues = (values: DropdownInternalValue) => void;

export const Dropdown = ({
  flip = true,
  show,
  trigger = "click",
  children,
  listSize,
  onChange,
  maxWidth,
  placement,
  hideOnClick,
  initialShow = false,
}: DropdownProps) => {
  const [internalValue, setInternalValue] = useState<DropdownInternalValue>({});

  const [setValues, setSetValues] = useState<DropdownSetSetValues>(
    () => undefined
  );

  return (
    <DropdownContext.Provider
      value={{
        flip,
        trigger,
        maxWidth,
        listSize,
        hideOnClick,
        setSetValues,
        setInternalValue,
      }}
    >
      <MenuProvider
        open={show}
        parent={null}
        values={internalValue}
        setOpen={onChange}
        focusShift
        focusWrap={false}
        focusLoop="vertical"
        placement={placement}
        setValues={setValues}
        defaultOpen={initialShow}
      >
        {children}
      </MenuProvider>
    </DropdownContext.Provider>
  );
};

Dropdown.displayName = "Dropdown";
