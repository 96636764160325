import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
} from "react";

import type {
  DropdownInternalValue,
  DropdownProps,
  DropdownSetSetValues,
} from "./dropdown";

type Context =
  | {
      flip: DropdownProps["flip"];
      trigger: DropdownProps["trigger"];
      maxWidth?: DropdownProps["maxWidth"];
      listSize?: DropdownProps["listSize"];
      hideOnClick?: DropdownProps["hideOnClick"];
      setSetValues?: Dispatch<SetStateAction<DropdownSetSetValues>>;
      setInternalValue?: Dispatch<SetStateAction<DropdownInternalValue>>;
    }
  | undefined;

export const DropdownContext = createContext<Context>(undefined);

export const useDropdown = () => {
  const context = useContext(DropdownContext);

  if (!context) throw new Error("useDropdown must be used within a Dropdown");

  return context;
};
