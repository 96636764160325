import { type z } from "zod";

import { type Types } from "./response";

export const CATEGORIES: Record<Lowercase<z.output<typeof Types>>, string> = {
  vendor: "Vendor",
  customer: "Job",
  purchaseorder: "Purchase order",
  bill: "Bill",
  expense: "Receipt",
  invoice: "Invoice",
  line: "Line",
  invoiceline: "Invoice line",
  comment: "Comment",
  change: "Change",
  changeline: "Change line",
  cardtransactionexpenselink: "Card transaction",
};

export const KEYS = Object.keys(CATEGORIES) as (keyof typeof CATEGORIES)[];

export const LINKS: Record<keyof typeof CATEGORIES, string> = {
  vendor: `/vendors/{{record.id}}`,
  customer: `/jobs/{{record.id}}`,
  purchaseorder: `/purchase-orders/{{record.id}}`,
  bill: `/bills/{{record.id}}`,
  expense: `/expenses/{{record.id}}`,
  invoice: `/invoices/{{record.id}}`,
  line: `/{{record.urlName}}/{{record.id}}`,
  invoiceline: `/{{record.urlName}}/{{record.id}}`,
  comment: `/{{record.urlName}}/{{record.id}}`,
  change: `/{{record.urlName}}/{{record.id}}`,
  changeline: `/{{record.urlName}}/{{record.id}}`,
  cardtransactionexpenselink: `/{{record.urlName}}/{{record.id}}`,
};
