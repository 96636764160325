import React, { type ReactNode } from "react";
import { Button, dialog, Icon, Image, toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { api } from "@utils/api";

import styles from "./attachable.module.css";

export type AttachableProps = {
  action?: ReactNode;
  attachable: {
    id: string;
    pdf?: string;
    document?: string;
    file?: string;
  };
  onDelete?: (id: string) => void;
};

const isPdf = (str?: string) =>
  !!(str && new URL(str.toLowerCase()).pathname.endsWith("pdf"));

export const Attachable = ({
  action,
  onDelete,
  attachable,
}: AttachableProps) => {
  let pdfData = null;

  if (attachable.pdf) {
    if (isPdf(attachable.pdf)) {
      pdfData = attachable.pdf;
    }
  } else if (isPdf(attachable.document)) {
    pdfData = attachable.document;
  } else if (isPdf(attachable.file)) {
    pdfData = attachable.file;
  }

  const enhancedOnDelete = useEvent(() => {
    const handler = async () => {
      await api.delete(`/api/attachables/${attachable.id}`);
      onDelete?.(attachable.id);
      toast.success("Attachment deleted");
    };

    dialog.confirmation({
      title: "Delete file?",
      message: "Are you sure you want to delete the attached file?",
      action: {
        primary: { children: "Delete file", color: "error", onClick: handler },
      },
    });
  });

  return (
    <div className="pdf-viewer">
      <div className={styles["actions"]}>
        <Button
          as="a"
          rel="noreferrer"
          href={pdfData || attachable.document}
          size="sm"
          color="neutral"
          target="_blank"
          variant="ghost"
          download
          aria-label="Download attachment"
          data-skip-focusable=""
        >
          <Icon name="arrow-down-to-bracket" />
        </Button>
        {onDelete && (
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            onClick={enhancedOnDelete}
            aria-label="Delete attachment"
            data-skip-focusable=""
          >
            <Icon name="trash" />
          </Button>
        )}
        {action}
      </div>
      {pdfData ? (
        <embed
          data-skip-focusable=""
          src={`${pdfData}#view=FitH&toolbar=1&navpanes=0`}
          height="100%"
          width="100%"
          type="application/pdf"
        />
      ) : (
        <div className="preview-wrapper">
          <Image alt="Document" src={attachable.document} />
        </div>
      )}
    </div>
  );
};
