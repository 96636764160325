import { handleErrors } from "@api/handle-errors";
import { type z } from "zod";

export const handleResponse = <Response>(
  response: Response,
  schema: z.ZodSchema
) => {
  try {
    return schema.parse(response);
  } catch (error: unknown) {
    handleErrors(error);
  }
};

export const handleRequest = <Request>(
  request: Request,
  schema: z.ZodSchema
) => {
  return schema.parse(request);
};
