import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { Link as ReactRouterLink, useSearchParams } from "react-router-dom";
import {
  Alert as DSAlert,
  AlertContent,
  AlertTitle,
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Loader,
  Table as DSTable,
  TableConfigurationButton,
  type TableHeaderAddon,
  type TablePaginationAddon,
  TableProvider,
  type TableSelectAddon,
  type TableSortAddon,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
  Text,
  toast,
} from "@adaptive/design-system";
import {
  useDialog,
  useEvent,
  usePagination,
} from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { CounterLabel } from "@components/counter-label";
import { SelectVendor } from "@components/form";
import {
  Main,
  MainContent,
  MainHeader,
  MainSubtitle,
  MainTitle,
} from "@components/main";
import {
  Sticky,
  StickyMeasurer,
  StickyProvider,
  type StickyProviderOnResizeHandler,
} from "@components/sticky";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useBankAccountsV2 } from "@hooks/use-bank-accounts";
import { BasePermissions, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { noop } from "@utils/noop";
import { scrollMainTop } from "@utils/scroll-main-top";

import { useBatchUpdateCardTransactionsMutation } from "../api/api";
import type { CardTransaction } from "../api/types";
import {
  ALL_CARD_TRANSACTION_FILTERS,
  ALL_EMPTY_STATE,
  DEFAULT_FILTERS,
  DEFAULT_STATUS,
  FILTER_CONFLICT_EMPTY_STATE,
  FILTERS_STORAGE_KEY,
  MY_TRANSACTIONS_KEY,
  NO_CARD_EMPTY_STATE,
  STATUS,
  STATUS_FILTER,
  STRINGS,
  TABLE_ID,
  TABLE_LOCAL_STORAGE_LIMIT_KEY,
  UNMATCHED_CARD_TRANSACTION_FILTERS,
  UNMATCHED_EMPTY_STATE,
} from "../constants/constants";
import { useCardTransactions } from "../hooks/use-card-transactions";
import { cardTransactionsFilterFormatter } from "../utils/card-transactions-filter-formatter";

import { CreateReceiptsDialog } from "./create-receipts-dialog";
import { Filter as FilterControls } from "./misc";
import { getColumns } from "./page-columns";
import {
  Provider,
  useActions,
  useHasFilterConflict,
  useInfo,
  useMyTransactions,
  useOrder,
  usePage,
  usePermissions,
  usePerPage,
  useRawFilters,
  useSelectedTransactions,
  useStatus,
  useTableOffset,
  useUnmatchedInfo,
} from "./page-context";
import type { Permissions, Status } from "./types";

const Alert = memo(() => {
  const { isPlaidLoginRequired } = useBankAccountsV2({ accountType: "credit" });

  return (
    isPlaidLoginRequired && (
      <DSAlert variant="error">
        <AlertTitle>{STRINGS.EXPIRED_CREDENTIALS_ALERT_TITLE}</AlertTitle>
        <AlertContent as={Flex} gap="sm" wrap align="center">
          Please{" "}
          <Link as={ReactRouterLink} to="/settings/company/cards">
            re-authenticate your bank account
          </Link>{" "}
          to continue pulling transactions
        </AlertContent>
      </DSAlert>
    )
  );
});

Alert.displayName = "CardFeedAlert";

const Filter = memo(() => {
  const dialog = useDialog({ lazy: true });

  const status = useStatus();

  const filters = useRawFilters();

  const vendorSelector = useCallback(() => undefined, []);

  const myTransactions = useMyTransactions();

  const selectedTransactions = useSelectedTransactions();

  const { canEditTransaction } = usePermissions();

  const canEdit = selectedTransactions.every(canEditTransaction);

  const [batchUpdateCardTransactions, batchUpdateCardTransactionsInfo] =
    useBatchUpdateCardTransactionsMutation();

  const {
    clearFilters,
    setFilters,
    setMyTransactions,
    setSelectedTransactions,
  } = useActions();

  const onArchive = useEvent(async () => {
    try {
      await batchUpdateCardTransactions({
        cardTransactionIds: selectedTransactions.map(({ id }) => id),
        isArchived: true,
      });

      setSelectedTransactions([]);
    } catch (e) {
      handleErrors(e);
    }
  });

  const curriedOnChange = useCallback(
    (field: string) => async (value?: string) => {
      try {
        await batchUpdateCardTransactions({
          cardTransactionIds: selectedTransactions.map(({ id }) => id),
          [field]: value ?? null,
        });

        setSelectedTransactions([]);
      } catch (e) {
        handleErrors(e);
      }
    },
    [batchUpdateCardTransactions, selectedTransactions, setSelectedTransactions]
  );

  return (
    <>
      {batchUpdateCardTransactionsInfo.isLoading && <Loader position="fixed" />}
      <FilterControls
        status={status}
        filters={filters}
        onClear={clearFilters}
        onChange={setFilters}
        myTransactions={myTransactions}
        onChangeMyTransactions={setMyTransactions}
      >
        <Flex gap="md">
          <TableConfigurationButton />
          {status === "unmatched" &&
            canEdit &&
            selectedTransactions.length > 0 && (
              <Dropdown>
                <DropdownTrigger as={Button}>
                  {STRINGS.BATCH_ACTION_TRIGGER}
                  <Icon name="ellipsis-vertical" variant="solid" />
                </DropdownTrigger>
                <DropdownList>
                  <DropdownItem onClick={dialog.show}>
                    {STRINGS.BATCH_ACTION_CREATE}
                  </DropdownItem>
                  <DropdownItem onClick={onArchive}>
                    {STRINGS.BATCH_ACTION_ARCHIVE}
                  </DropdownItem>
                  <DropdownList label={STRINGS.BATCH_ACTION_EDIT}>
                    <DropdownItem>
                      <SelectVendor
                        flip
                        size="sm"
                        label=""
                        portal
                        selector={vendorSelector}
                        listSize={4}
                        disabled={batchUpdateCardTransactionsInfo.isLoading}
                        onChange={curriedOnChange("vendor")}
                        placeholder={
                          STRINGS.BATCH_ACTION_EDIT_VENDOR_PLACEHOLDER
                        }
                        messageVariant="hidden"
                      />
                    </DropdownItem>
                  </DropdownList>
                </DropdownList>
              </Dropdown>
            )}
        </Flex>
      </FilterControls>
      {dialog.isRendered && (
        <CreateReceiptsDialog
          dialog={dialog}
          transactions={selectedTransactions}
          onSuccess={() => setSelectedTransactions([])}
        />
      )}
    </>
  );
});

Filter.displayName = "CardFeedFilter";

const Table = memo(() => {
  const page = usePage();

  const order = useOrder();

  const status = useStatus();

  const perPage = usePerPage();

  const tableOffset = useTableOffset();

  const hasFilterConflict = useHasFilterConflict();

  const selectedTransactions = useSelectedTransactions();

  const { data, count, isLoading } = useInfo();

  const { canEditCompany, canEditTransaction } = usePermissions();

  const {
    setPage,
    setOrder,
    setPerPage,
    removeUsersFilter,
    setSelectedTransactions,
    disableMyTransactionsFilter,
  } = useActions();

  const hasData = data.length > 0;

  const header = useMemo<TableHeaderAddon>(
    () => ({ hide: !hasData, sticky: { offset: tableOffset } }),
    [tableOffset, hasData]
  );

  const select = useMemo<TableSelectAddon<CardTransaction> | undefined>(
    () =>
      status === "unmatched"
        ? {
            value: selectedTransactions,
            onChange: setSelectedTransactions,
            isDisabled: (row) => !canEditTransaction(row),
          }
        : undefined,
    [status, canEditTransaction, selectedTransactions, setSelectedTransactions]
  );

  const columns = useMemo(
    () => getColumns({ match: status === "all", status: status === "all" }),
    [status]
  );

  /**
   * @todo dynamic check if user has linked card
   */
  const hasLinkedCard = true;

  const emptyState = useMemo(() => {
    if (hasFilterConflict) {
      return {
        ...FILTER_CONFLICT_EMPTY_STATE,
        action: (
          <Flex gap="lg">
            <Button onClick={removeUsersFilter}>Remove User filter</Button>
            <Button onClick={disableMyTransactionsFilter}>
              Disable My transactions toggle
            </Button>
          </Flex>
        ),
      };
    }

    if (!hasLinkedCard) {
      return {
        ...NO_CARD_EMPTY_STATE,
        action: canEditCompany ? (
          <Button as={ReactRouterLink} to="/settings/company/cards">
            {STRINGS.CONNECT_CARD_ACTION}
          </Button>
        ) : null,
      };
    }

    return status === STATUS.UNMATCHED
      ? UNMATCHED_EMPTY_STATE
      : ALL_EMPTY_STATE;
  }, [
    status,
    hasLinkedCard,
    canEditCompany,
    removeUsersFilter,
    hasFilterConflict,
    disableMyTransactionsFilter,
  ]);

  const sort = useMemo<TableSortAddon>(
    () => ({ value: order, onChange: setOrder }),
    [order, setOrder]
  );

  const pagination = useMemo<TablePaginationAddon>(
    () => ({
      page,
      total: count,
      perPage,
      onChange: setPage,
      perPageVariant: "lg",
      onPerPageChange: (perPage) => {
        setPage(0);
        setPerPage(perPage);
        analytics.track("perPageLimitChange", {
          location: "card-feed-table",
          limit: perPage,
        });
      },
    }),
    [count, page, perPage, setPage, setPerPage]
  );

  return (
    <DSTable
      size="sm"
      sort={sort}
      data={data}
      select={select}
      header={header}
      loading={isLoading}
      columns={columns}
      pagination={pagination}
      emptyState={emptyState}
    />
  );
});

Table.displayName = "CardFeedTable";

const Content = memo(() => {
  const status = useStatus();

  const unmatched = useUnmatchedInfo();

  const { setPage, setStatus, setTableOffset, setSelectedTransactions } =
    useActions();

  const onTabChange = useEvent((status: string) => {
    setPage(0);
    setStatus(status as Status);
    setSelectedTransactions([]);
  });

  const onStickyResize = useEvent<StickyProviderOnResizeHandler>(({ sticky }) =>
    setTableOffset(sticky.height)
  );

  return (
    <Flex
      gap="xl"
      shrink={false}
      style={{
        /* We need to this calc to compensate margin from MainContent */
        minHeight: "calc(var(--spacing-full) - var(--spacing-4xl))",
      }}
      direction="column"
    >
      <Alert />
      <Flex minHeight="full" direction="column">
        <Tabs value={status} onChange={onTabChange}>
          <TabsList>
            <TabsTab value={STATUS.UNMATCHED}>
              <CounterLabel
                label={STRINGS.UNMATCHED_TAB}
                active={status === STATUS.UNMATCHED}
                loading={unmatched.isLoading}
                counter={unmatched.count}
              />
            </TabsTab>
            <TabsTab value={STATUS.ALL}>{STRINGS.ALL_TAB}</TabsTab>
          </TabsList>
          <TabsPanel>
            <TableProvider id={TABLE_ID}>
              <StickyProvider onResize={onStickyResize}>
                <Sticky
                  style={{
                    paddingTop: "var(--spacing-2xl)",
                    paddingBottom: "var(--spacing-lg)",
                  }}
                >
                  <Filter />
                </Sticky>
                <StickyMeasurer>
                  <Table />
                </StickyMeasurer>
              </StickyProvider>
            </TableProvider>
          </TabsPanel>
        </Tabs>
      </Flex>
    </Flex>
  );
});

Content.displayName = "CardFeedContent";

export const Page = memo(() => {
  const { hasPermission, user } = useUserInfo();

  const hasFilterConflictToastRef = useRef(false);

  const closeFilterConflictToastRef =
    useRef<ReturnType<typeof toast.warning>["dismiss"]>(noop);

  const pagination = usePagination({
    localStorageKey: TABLE_LOCAL_STORAGE_LIMIT_KEY,
  });

  const [order, setOrder] = useState("-transaction_date");

  const [tableOffset, setTableOffset] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams({
    status: DEFAULT_STATUS,
  });

  const currentStatus =
    (searchParams.get("status") as Status) || DEFAULT_STATUS;

  const [selectedTransactions, setSelectedTransactions] = useState<
    CardTransaction[]
  >([]);

  const filters = useTableFilters({
    formatter: cardTransactionsFilterFormatter,
    storageKey: FILTERS_STORAGE_KEY,
  });

  /**
   * That logic right here is a little bit tricky. The idea here is to have
   * one filter for both tabs, but on "all" tab we have a filter for `status`
   * and we don't want to use it on "unmatched tab". So we need to filter it
   * out but keep the rest of the filters.
   */
  const unmatchedFilters = useMemo(
    () => ({
      ...filters,
      filters: filters.filters?.filter(
        (item) => !STATUS_FILTER.some(({ value }) => value === item.dataIndex)
      ),
      rawFilters: Object.entries(filters.rawFilters).reduce(
        (acc, [key, value]) =>
          STATUS_FILTER.some(({ value }) => value === key)
            ? acc
            : { ...acc, [key]: value },
        {}
      ),
      setFilters: ((nextFilters) => {
        const allFilters = Object.entries(filters.rawFilters).reduce(
          (acc, [key, value]) =>
            STATUS_FILTER.some(({ value }) => value === key)
              ? { ...acc, [key]: value }
              : acc,
          {}
        );

        return filters.setFilters({ ...allFilters, ...nextFilters });
      }) as typeof filters.setFilters,
    }),
    [filters]
  );

  const currentFilters =
    currentStatus === STATUS.UNMATCHED ? unmatchedFilters : filters;

  const permissions = useMemo<Permissions>(() => {
    const canEdit = hasPermission(BasePermissions.EDIT_ALL_EXPENSES);

    return {
      canEdit,
      canEditCompany: hasPermission(BasePermissions.COMPANY_ADMIN),
      canEditTransaction: (transaction) =>
        canEdit || transaction.user?.id == user.id,
    };
  }, [hasPermission, user.id]);

  const all = useCardTransactions({
    query: ALL_CARD_TRANSACTION_FILTERS,
    order: { order, setOrder },
    filters,
    pagination,
    initialFilters: DEFAULT_FILTERS,
  });

  const unmatched = useCardTransactions({
    query: UNMATCHED_CARD_TRANSACTION_FILTERS,
    order: { order, setOrder },
    filters: unmatchedFilters,
    pagination,
    initialFilters: DEFAULT_FILTERS,
  });

  const query = useMemo(() => {
    const filtersToOmit = [
      ...UNMATCHED_CARD_TRANSACTION_FILTERS,
      ...ALL_CARD_TRANSACTION_FILTERS,
    ];
    return [...all.filters, ...unmatched.filters].filter(
      (item, index, self) =>
        !filtersToOmit.some((omit) => omit.dataIndex === item.dataIndex) &&
        self.findIndex((t) => t.dataIndex === item.dataIndex) === index
    );
  }, [all.filters, unmatched.filters]);

  const onCloseFilterConflictToast = useEvent(() => {
    hasFilterConflictToastRef.current = false;
  });

  const removeUsersFilter = useEvent(() => {
    closeFilterConflictToastRef.current();
    enhancedSetFilters(
      Object.entries(currentFilters.rawFilters).reduce(
        (acc, [key, value]) =>
          key.includes("/users/") ? acc : { ...acc, [key]: value },
        {}
      )
    );
  });

  const disableMyTransactionsFilter = useEvent(() => {
    closeFilterConflictToastRef.current();
    setMyTransactions(false);
  });

  const setPage = useEvent((page: number) => {
    pagination.setPage(page);
    scrollMainTop(0);
  });

  const enhancedSetFilters = useEvent(
    (nextFilters: typeof currentFilters.rawFilters) => {
      if (isEqual(nextFilters, currentFilters.rawFilters)) return nextFilters;

      const hasUser = Object.keys(nextFilters).some((key) =>
        key.includes("/users/")
      );

      const hasMyTransactionsUser = !!nextFilters[MY_TRANSACTIONS_KEY];

      const hasChangedMyTransactionsUser =
        hasMyTransactionsUser &&
        !currentFilters.rawFilters[MY_TRANSACTIONS_KEY];

      if (
        hasUser &&
        hasMyTransactionsUser &&
        !hasFilterConflictToastRef.current
      ) {
        hasFilterConflictToastRef.current = true;

        const { dismiss } = toast.warning(
          <Flex as="span" direction="column">
            <Text as="strong" weight="bold">
              {STRINGS.FILTER_CONFLICT_TOOLTIP_MESSAGE}
            </Text>
            <Flex gap="lg">
              {hasChangedMyTransactionsUser ? (
                <Link as="button" type="button" onClick={removeUsersFilter}>
                  {STRINGS.FILTER_CONFLICT_TOOLTIP_USER_ACTION}
                </Link>
              ) : (
                <Link
                  as="button"
                  type="button"
                  onClick={disableMyTransactionsFilter}
                >
                  {STRINGS.FILTER_CONFLICT_TOOLTIP_MY_TRANSACTIONS_ACTION}
                </Link>
              )}
            </Flex>
          </Flex>,
          { duration: 10000, onClose: onCloseFilterConflictToast }
        );

        closeFilterConflictToastRef.current = dismiss;
      } else if (
        hasFilterConflictToastRef.current &&
        ((hasUser && !hasMyTransactionsUser) ||
          (!hasUser && hasMyTransactionsUser))
      ) {
        closeFilterConflictToastRef.current();
      }

      setSelectedTransactions([]);

      return currentFilters.setFilters(nextFilters);
    }
  );

  const myTransactions =
    currentFilters.rawFilters[MY_TRANSACTIONS_KEY] === user.id;

  const setStatus = useEvent((status: Status) => {
    setSearchParams({ status });
  });

  const setMyTransactions = useEvent((value) =>
    enhancedSetFilters({
      ...currentFilters.rawFilters,
      [MY_TRANSACTIONS_KEY]: value ? user.id : undefined,
    })
  );

  const clearFilters = useEvent(() => enhancedSetFilters({}));

  return (
    <Main>
      <MainHeader>
        <Flex align="center" height="full" gap="xl">
          <Flex direction="column" grow>
            <MainTitle>{STRINGS.TITLE}</MainTitle>
            <Flex gap="sm" wrap align="baseline">
              <MainSubtitle>{STRINGS.SUBTITLE}</MainSubtitle>
              {permissions.canEditCompany && (
                <Link
                  as={ReactRouterLink}
                  to="/settings/company/cards"
                  variant="success"
                >
                  {STRINGS.MANAGE_CARDS_ACTION}
                </Link>
              )}
            </Flex>
          </Flex>
          <Button
            as="a"
            href="https://help.adaptive.build/en/articles/9750251-new-expenses-experience-beta-release"
            color="neutral"
            target="_blank"
            variant="ghost"
            onClick={() => analytics.track("cardFeedTutorial")}
          >
            <Icon name="info-circle" />
            {STRINGS.VIEW_TUTORIAL_ACTION}
          </Button>
        </Flex>
      </MainHeader>
      <MainContent>
        <Provider
          value={{
            all,
            status: currentStatus,
            setPage,
            filters: query,
            setOrder,
            unmatched,
            setStatus,
            rawFilters: currentFilters.rawFilters,
            setPerPage: pagination.setPerPage,
            setFilters: enhancedSetFilters,
            tableOffset,
            permissions,
            clearFilters,
            myTransactions,
            setTableOffset,
            removeUsersFilter,
            setMyTransactions,
            selectedTransactions,
            setSelectedTransactions,
            disableMyTransactionsFilter,
          }}
        >
          <Content />
        </Provider>
      </MainContent>
    </Main>
  );
});

Page.displayName = "CardFeedPage";
