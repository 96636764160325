import React, { type PropsWithChildren } from "react";
import { TabProvider } from "@ariakit/react";

type TabsProps = PropsWithChildren<{
  value?: string;
  onChange?: (value: string) => void;
}>;

export const Tabs = ({ value, children, onChange }: TabsProps) => {
  return (
    <TabProvider
      activeId={value}
      selectedId={value}
      setSelectedId={(value) => onChange?.(value || "")}
    >
      {children}
    </TabProvider>
  );
};
