import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useId,
  useState,
} from "react";
import cn from "clsx";

import { type ResponsiveProp, useResponsiveProp } from "../../hooks";
import { getInitials } from "../../utils/get-initials";
import { isIcon } from "../../utils/is-icon";
import { suffixify } from "../../utils/suffixify";
import { Button } from "../button";
import { Flex } from "../flex";
import { Icon, type IconProps } from "../icon";
import { Text } from "../text";
import { Wrapper } from "../wrapper";

import styles from "./timeline.module.css";

type DefaultComponent = "ul";

type Ref = ComponentRef<DefaultComponent>;

type ExtraData = Data;

export type Data = PropsWithChildren<{
  icon?: string | Pick<IconProps, "name" | "flip" | "variant">;
  id?: string;
  title?: ReactNode;
  color?: "neutral" | "error" | "success" | "info" | "warning";
  extra?: ExtraData[];
  variant?: "solid" | "hollow";
  subtitle?: ReactNode;
  renderHeader?: (children: ReactNode) => JSX.Element;
}>;

export type Props = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "size" | "onChange" | "value"
> & {
  data?: Data[];
  separator?: boolean;
  size?: ResponsiveProp<"sm" | "md">;
};

const ICON_SIZING = {
  sm: "xs",
  md: "sm",
} as const;

type ExtraProps = Data & {
  isActive: boolean;
  reference: string | number;
  iconSize?: IconProps["size"];
};

const Extra = ({
  extra,
  subtitle,
  isActive,
  reference,
  children,
  iconSize = "sm",
}: ExtraProps) => (
  <ul
    id={suffixify(reference, "panel")}
    className={cn(styles["timeline"], styles["-inner"], {
      [styles["-active"]]: isActive,
      [styles["-space-top"]]: children || subtitle,
    })}
    aria-labelledby={suffixify(reference, "trigger")}
  >
    {(extra || []).map((extraItem, extraIndex) => {
      const extraDataItem = extraItem.extra || [];

      const extraItemIcon = !extraItem.icon
        ? undefined
        : typeof extraItem.icon === "string"
          ? { name: extraItem.icon }
          : extraItem.icon;

      return (
        <li
          key={suffixify(reference, extraIndex)}
          className={cn(styles["item"], {
            [styles[`-${extraItem.color ?? "neutral"}`]]:
              extraItem.color ?? "neutral",
          })}
          id={extraItem.id}
        >
          {(extraItem.title || extraItem.icon) && (
            <div className={styles["header"]}>
              {extraItemIcon && (
                <div className={styles["icon"]}>
                  {isIcon(extraItemIcon.name) ? (
                    <Icon size={iconSize} {...(extraItemIcon as IconProps)} />
                  ) : (
                    getInitials(extraItemIcon.name)
                  )}
                </div>
              )}
              {extraItem.title && (
                <Flex direction="column" grow>
                  <Text as="strong" size="sm" weight="bold" truncate>
                    {extraItem.title}
                  </Text>
                  {extraItem.subtitle && (
                    <small className={styles["subtitle"]}>
                      {extraItem.subtitle}
                    </small>
                  )}
                </Flex>
              )}
            </div>
          )}
          <div
            className={cn(styles["body"], {
              [styles["-space-top"]]: extraItem.subtitle,
              [styles["-space-left"]]: extraItemIcon,
              [styles[`-${extraItem.variant ?? "solid"}`]]:
                extraItem.variant ?? "solid",
            })}
          >
            {typeof extraItem.children === "string" ? (
              <p>{extraItem.children}</p>
            ) : (
              extraItem.children
            )}
          </div>
          {extraDataItem.length > 0 && (
            <Extra
              {...extraItem}
              extra={extraDataItem}
              reference={`${reference}-${extraIndex}`}
              isActive={isActive}
              iconSize={iconSize}
            />
          )}
        </li>
      );
    })}
  </ul>
);

Extra.displayName = "Extra";

export const Timeline = forwardRef<Ref, Props>(
  ({ data = [], separator, className, size, ...props }, ref) => {
    const id = useId();
    const spacing = useResponsiveProp(size, "md");
    const iconSize = ICON_SIZING[spacing];

    const [activated, setActivated] = useState<number[]>([]);

    const curriedOnToggle = useCallback(
      (index: number) => () => {
        setActivated((previousActivated) =>
          previousActivated.includes(index)
            ? previousActivated.filter((item) => item !== index)
            : [...previousActivated, index]
        );
      },
      []
    );

    const curriedExtraData = useCallback(
      (
        extraData: ExtraData[],
        item: Data,
        index: string,
        isActive: boolean
      ) => (
        <ul
          id={suffixify(id, index, "panel")}
          className={cn(styles["timeline"], styles["-inner"], {
            [styles["-active"]]: isActive,
            [styles["-space-top"]]: item.children,
          })}
          aria-labelledby={suffixify(id, index, "trigger")}
        >
          {extraData.map((extraItem, extraIndex) => {
            const extraDataItem = extraItem.extra || [];

            const extraItemIcon = !extraItem.icon
              ? undefined
              : typeof extraItem.icon === "string"
                ? { name: extraItem.icon }
                : extraItem.icon;

            return (
              <li
                key={suffixify(index, extraIndex)}
                className={cn(styles["item"], {
                  [styles[`-${extraItem.color ?? "neutral"}`]]:
                    extraItem.color ?? "neutral",
                })}
                id={extraItem.id}
              >
                {(extraItem.title || extraItem.icon) && (
                  <div className={styles["header"]}>
                    {extraItemIcon && (
                      <div className={styles["icon"]}>
                        {isIcon(extraItemIcon.name) ? (
                          <Icon
                            size={iconSize}
                            {...(extraItemIcon as IconProps)}
                          />
                        ) : (
                          getInitials(extraItemIcon.name)
                        )}
                      </div>
                    )}
                    {extraItem.title && (
                      <Flex direction="column" grow>
                        <Text as="strong" size="sm" weight="bold" truncate>
                          {extraItem.title}
                        </Text>
                        {extraItem.subtitle && (
                          <small className={styles["subtitle"]}>
                            {extraItem.subtitle}
                          </small>
                        )}
                      </Flex>
                    )}
                  </div>
                )}
                <div
                  className={cn(styles["body"], {
                    [styles["-space-top"]]: extraItem.subtitle,
                    [styles["-space-left"]]: extraItemIcon,
                    [styles[`-${extraItem.variant ?? "solid"}`]]:
                      extraItem.variant ?? "solid",
                  })}
                >
                  {typeof extraItem.children === "string" ? (
                    <p>{extraItem.children}</p>
                  ) : (
                    extraItem.children
                  )}
                </div>
                {extraDataItem.length > 0 &&
                  curriedExtraData(
                    extraDataItem,
                    extraItem,
                    `${index}-${extraIndex}`,
                    isActive
                  )}
              </li>
            );
          })}
        </ul>
      ),
      [iconSize, id]
    );

    return (
      <ul
        ref={ref}
        className={cn(className, styles["timeline"], {
          [styles["-separator"]]: separator,
          [styles[`-spacing-${spacing}`]]: spacing,
        })}
        {...props}
      >
        {data.map((item, index) => {
          const extraData = item.extra || [];

          const itemIcon = !item.icon
            ? undefined
            : typeof item.icon === "string"
              ? { name: item.icon }
              : item.icon;

          const isActive = activated.includes(index) || !!item.renderHeader;

          return (
            <li
              key={index}
              className={cn(styles["item"], {
                [styles[`-${item.color ?? "success"}`]]:
                  item.color ?? "success",
              })}
              id={item.id}
            >
              <Wrapper
                when={extraData.length > 0 || !!item.renderHeader}
                render={(children) =>
                  item.renderHeader ? (
                    item.renderHeader(children)
                  ) : (
                    <button
                      id={suffixify(id, index, "trigger")}
                      type="button"
                      onClick={curriedOnToggle(index)}
                      className={styles["button"]}
                      aria-expanded={isActive}
                      aria-controls={suffixify(id, index, "panel")}
                    >
                      <Flex align="center" width="full" gap="md">
                        {children}{" "}
                        <Button
                          as="span"
                          size="sm"
                          variant="ghost"
                          color="neutral"
                          focusable={false}
                        >
                          <Icon
                            name={isActive ? "chevron-up" : "chevron-down"}
                          />
                        </Button>
                      </Flex>
                    </button>
                  )
                }
              >
                {(item.title || itemIcon) && (
                  <div className={styles["header"]}>
                    {itemIcon && (
                      <div className={styles["icon"]}>
                        {isIcon(itemIcon.name) ? (
                          <Icon size={iconSize} {...(itemIcon as IconProps)} />
                        ) : (
                          getInitials(itemIcon.name)
                        )}
                      </div>
                    )}
                    {item.title && (
                      <Flex direction="column" grow>
                        <Text as="strong" size="sm" weight="bold" truncate>
                          {item.title}
                        </Text>
                        {item.subtitle && (
                          <small className={styles["subtitle"]}>
                            {item.subtitle}
                          </small>
                        )}
                      </Flex>
                    )}
                  </div>
                )}
              </Wrapper>
              {item.children && (
                <div
                  className={cn(styles["body"], {
                    [styles["-space-top"]]: item.subtitle,
                    [styles["-space-left"]]: itemIcon,
                    [styles[`-${item.variant ?? "solid"}`]]:
                      item.variant ?? "solid",
                  })}
                >
                  {typeof item.children === "string" ? (
                    <p>{item.children}</p>
                  ) : (
                    item.children
                  )}
                </div>
              )}
              {extraData.length > 0 && (
                <Extra
                  {...item}
                  extra={extraData}
                  reference={`${index}`}
                  isActive={isActive}
                  iconSize={iconSize}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  }
);

Timeline.displayName = "Timeline";
